//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'InfoBar',
  props: {
    link: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      barSets: 'barSets'
    }),
    exists () {
      const data = this.barSets[this.type]

      if (!data || !data?.content?.base) {
        return
      }
      return data
    },
    getIcon () {
      if (!this.exists) {
        return
      }
      const data = this.barSets[this.type]
      if (!data?.icon?.svg) {
        return
      }
      return data.icon.svg
    },
    getClasses () {
      if (!this.exists) {
        return
      }
      const data = this.barSets[this.type]
      if (!data.classes) {
        return
      }
      return data.classes
    },
    getLink () {
      if (!this.exists) {
        return
      }

      const data = this?.barSets[this.type]?.func?.getLink
      let result = ''

      if (!data) {
        return
      }
      switch (data) {
        case 'default':
          if (this.$t('ibar-link', this.locale) !== '-') {
            result = this.$t('ibar-link', this.locale)
          }
          break
        case 'campaign.register':
          const tags = {}
          this.$t('bar-campaign-utm-query')?.split(/\?|&/)?.forEach((i) => {
            if (!i) {
              return
            }
            const key = i.split('=')[0]
            const value = i.split('=')[1]
            tags[key] = value
          })

          result = this.$router.resolve({
            name: 'campaign.register', params: { lang: this.locale }, query: { ...this.$route.query, ...tags }
          }).href
          break
        default:
          break
      }
      return result
    },
    component () {
      if (this.link) {
        return 'nuxt-link'
      }
      if (this.getLink) {
        return 'a'
      }
      return 'div'
    },
    getContent () {
      if (!this.exists) {
        return
      }

      const data = this.barSets[this.type]

      let result = data.content.base
      if (data.content.needTranslate) {
        result = this.$t(result)
      }
      return result
    },
    getStyle () {
      if (!this.exists) {
        return
      }
      const data = this.barSets[this.type]
      return data.style
    }
  }
}
